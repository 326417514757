<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>监管主体</el-breadcrumb-item>
        <el-breadcrumb-item>监管管理</el-breadcrumb-item>
        <el-breadcrumb-item @click="$router.back()"
          >主体管理</el-breadcrumb-item
        >
        <el-breadcrumb-item> 数据字典</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          icon="el-icon-plus"
          size="medium"
          type="primary"
          @click="Add()"
          >添加数据字典</el-button
        >
        <el-button class="back_btn" size="medium" plain @click="$router.back()"
          >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="p15 pt15">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="数据字典名称"
              v-model="page.dictionary_name"
              clearable
              class="input-with-select"
              @clear="onSubmit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.state"
              clearable
              placeholder="全部"
              @change="onSubmit(page.state)"
            >
              <el-option
                v-for="(item, index) in statusOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">筛选</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search">
       <el-form :inline="true"  :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
             <el-form-item size="medium" >
         <el-input placeholder="数据字典名称" v-model="page.dictionary_name" clearable class="input-with-select" @clear="onSubmit">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.state" clearable placeholder="全部" @change="onSubmit(page.state)">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        <el-col :span="4" class="tl">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">筛选</el-button>
        </el-form-item>
        </el-col>
       
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="company_tag_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="主体类型名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="dictionary_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="字典名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="dictionary_module"
            min-width="80"
            :show-overflow-tooltip="true"
            label="字典模块名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="dictionary_field"
            min-width="80"
            :show-overflow-tooltip="true"
            label="字典字段值"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="add_time"
            min-width="100"
            :show-overflow-tooltip="true"
            label="添加时间"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            label="状态"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                class="f12"
                :class="scope.row.state === 1 ? 'c67c' : 'ce6a'"
                >{{ scope.row.state | statusFilter }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            class-name="aa"
            fixed="right"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="orange disib"
                size="mini"
                @click="
                  goUpdate(
                    scope.row.company_tag_id,
                    scope.row.dictionary_id,
                    scope.row.company_tag_name
                  )
                "
                >编辑</el-button
              >
              <el-button
                type="text"
                class="disib"
                :class="{ red: scope.row.state == 1 }"
                size="mini"
                @click="changeState(scope.row)"
                >{{ scope.row.state | antiStateFilter }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, aa, session } from "../../../util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        company_tag_name: "",
        company_type_id: "",
        company_tag_id: "",
        state: "",
        dictionary_name: "",
      },
      user: "",
      timeVisible: false,
      timer: "",
      loading: false,
      options4: "",
      options2: "",
      activeClass: 0,
      currentPage: 1,
      total: 0,
      count: "",
      tableData: [],
      statusOptions: [
        { label: "全部", value: "" },
        { label: "已启用", value: "1" },
        { label: "已禁用", value: "2" },
      ],
      typetOptions: [],
      tagOptions: [],
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用",
      };
      return statusMap[status];
    },
    tagFilter(status) {
      const statusMap = {
        1: "success",
        2: "warning",
      };
      return statusMap[status];
    },
    antiStateFilter(state) {
      const stateMap = {
        1: "禁用",
        2: "启用",
      };
      return stateMap[state];
    },
  },
  created() {
    var that = this;
    that.user = local.get("user");
    that.currentPage = that.page.pager_offset / 10 + 1;
    this.page.company_tag_name = session.get("params1").name;
    this.page.company_type_id = session.get("params1").id
      ? String(session.get("params1").id)
      : "";
    this.page.company_tag_id = session.get("params1").id1
      ? String(session.get("params1").id1)
      : "";
    // this.getTag(this.page.company_type_id)
    that.getList(that.page);
  },
  methods: {
    // getType(){
    //      var that=this;
    //     axios.get('/pc/company-type/all').then((v) => {
    //     that.typetOptions=v.data.company_type_list;
    //        that.page.company_type_id=String(that.typetOptions[0].company_type_id)
    //      this.getTag(this.page.company_type_id)
    // })
    // },
    getList(params) {
      axios.get("/pc/dictionary/list", params).then((v) => {
        this.tableData = v.data.dictionary_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      var that = this;
      that.currentPage = 1;
      that.page.pager_offset = "0";
      that.page.company_type_id = String(that.page.company_type_id);
      that.page.company_tag_id = String(that.page.company_tag_id);
      that.getList(that.page);
    },
    // 分页
    currentChange(page) {
      var that = this;
      that.currentPage = page;
      that.page.pager_offset = String((page - 1) * 10);
      that.getList(that.page);
    },
    Add() {
      session.set("params2", {
        id: this.page.company_type_id,
        id1: "",
        name: this.page.company_tag_name,
      });
      this.$router.push({ path: "dictionary/add" });
      // $router.push({path: 'dictionary/add',query:{company_tag_id:page.company_tag_id,company_tag_name:page.company_tag_name,dictionary_id:''}})
    },
    //编辑
    goUpdate(id, id1, id2) {
      session.set("params2", { id: id, id1: id1, name: id2 });
      this.$router.push({ name: "DictionaryAdd" });
    },
    //更改状态
    changeState(row) {
      let { dictionary_id, state } = row;
      state = state == 1 ? 2 : 1;
      axios
        .put("/pc/dictionary/state/update", { dictionary_id, state })
        .then((response) => {
          this.getList(this.page);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
